import useJwt from '@src/auth/jwt/useJwt';

const initialState = {
    diariosEditar: [],
};

const config = useJwt.jwtConfig;

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case 'setDiarioEditar':
            return {...state, diariosEditar: action.payload.diariosEditar};

        default:
            return state;
    }
};