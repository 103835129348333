import mock from '../mock'

const data =  
{
  id_reuniao: 6,
  id_reuniao_ref: "",
  reuniao: "RCOG VENDAS X LOGISTICA",
  descricao: "Reunião de alinhamento com a turma",
  quando: "10/02/2021 18:00",
  status: 0,
  perct_atividades: "100%",
  perct_assuntos: "90%",
  perct_participacao: "95",
  perct_pendente: "10",
  status_calendario:'noprazo',
  data_hora_inicio:'',
  data_hora_fim:'',
  ttAtividades:'',
  ttAssuntos: '',
  ttParticipacao: '',

  donos: [
    {
      id_usuario: 2,
      title: 'Vinnie Mostowy',
      img: 'https://quickgestao.s3.sa-east-1.amazonaws.com/avatar/vSuwq18iyhHJaICAIwvv.jpg'
    },
    {
      id_usuario: 1,
      title: "Cristian Cardono",
      img: "https://quickgestao.s3.sa-east-1.amazonaws.com/avatar/vSuwq18iyhHJaICAIwvv.jpg"
    }
  ],
  participantes: [
    {
      id_usuario: 2,
      title: 'Vinnie Mostowy',
      img: 'https://quickgestao.s3.sa-east-1.amazonaws.com/avatar/vSuwq18iyhHJaICAIwvv.jpg',
      status: false
    },
    {
      id_usuario: 3,
      title: 'Vinnie Mostowy',
      img: 'https://quickgestao.s3.sa-east-1.amazonaws.com/avatar/vSuwq18iyhHJaICAIwvv.jpg',
      status: false
    },
    {
      id_usuario: 4,
      title: 'Vinnie Mostowy',
      img: 'https://quickgestao.s3.sa-east-1.amazonaws.com/avatar/vSuwq18iyhHJaICAIwvv.jpg',
      status: false
    }
  ],
  atividades: [
    {
      id_atividade: 3,
      responsavel: "João da Silva",
      avatar: "https://quickgestao.s3.sa-east-1.amazonaws.com/avatar/vSuwq18iyhHJaICAIwvv.jpg",
      assunto: "Realizar troca do PC",
      prazo:"12/02/2021",
      criado:"11/02/2021 18:00",
      status:1
      
    },
    {
      id_atividade: 2,
      responsavel: "Clebrer da Silva",
      avatar: "https://quickgestao.s3.sa-east-1.amazonaws.com/avatar/vSuwq18iyhHJaICAIwvv.jpg",
      assunto: "Levar o gado pra comer",
      prazo:"12/02/2021",
      criado:"11/02/2021 18:00",
      status:1,
     
      
    },
    {
      id_atividade: 1,
      responsavel: "João da Baila",
      avatar: "https://quickgestao.s3.sa-east-1.amazonaws.com/avatar/vSuwq18iyhHJaICAIwvv.jpg",
      assunto: "Trocar a lampada",
      prazo:"12/02/2021",
      criado:"11/02/2021 18:00",
      status:1
    }
  ],
  assuntos: [
    {
      id_assunto: 18,
      assunto: "Férias dos colaboradores",
      status:true,
      atividades: [],
      comentarios: []
    },
    {
      id_assunto: 12,
      assunto: "Vale alimentação",
      status:false,
      atividades: [],
      comentarios: []
    },
    {
      id_assunto: 11,
      assunto: "Assuntos de segurança",
      status:false,
      atividades: [],
      comentarios: []
    },
   
  ]
  
}


const dataUsuarios =  
{
  usuarios: [
    {
      id_usuario: 2,
      label: "Willian Faee",
      title: "Willian Faee",
      value: 2,
      img: 'https://quickgestao.s3.sa-east-1.amazonaws.com/avatar/vSuwq18iyhHJaICAIwvv.jpg'
    },
    {
      id_usuario: 1,
      label: "João Vicente",
      title: "João Vicente",
      value: 1,


      img: "https://quickgestao.s3.sa-east-1.amazonaws.com/avatar/vSuwq18iyhHJaICAIwvv.jpg"
    },
    {
      id_usuario: 3,
      label: "Joaquim Moreira",
      title: "Joaquim Moreira",
      value: 3,

      img: "https://quickgestao.s3.sa-east-1.amazonaws.com/avatar/vSuwq18iyhHJaICAIwvv.jpg"
    },
    {
      id_usuario: 4,
      label: "Marcelo Campos",
      title: "Marcelo Campos",
      value: 4,

      img: "https://quickgestao.s3.sa-east-1.amazonaws.com/avatar/vSuwq18iyhHJaICAIwvv.jpg"
    },
    {
      id_usuario: 5,
      value: "Massa da Cardono",
      title: "Massa da Cardono",
      label: "Giraia do avoo",
      value: 5,


      img: "https://quickgestao.s3.sa-east-1.amazonaws.com/avatar/vSuwq18iyhHJaICAIwvv.jpg"
    }
  ],
 
  
  
}



mock.onGet('/apps/meetings/meeting').reply(() => [200, data])
mock.onGet('/apps/meetings/users').reply(() => [200, dataUsuarios])

