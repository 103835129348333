const initialState = {
    checklist: '',
    picker: '',
    empresa: '',


    
};

export default (state = initialState, action = {}) => {
    switch(action.type) {
        case 'setChecklist':
            return {...state, checklist: action.payload.checklist};
        break;
        case 'setPicker':
            return {...state, picker: action.payload.picker};
        break;
        case 'setEmpresa':
            return {...state, empresa: action.payload.empresa};
        break;
    }
    return state;
};

