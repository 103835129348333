import React, { createContext, useContext, useReducer } from 'react';
import userReducer  from "./userReducer";
import checklistReducer from './checklistReducer'; 
import diarioReducer from './diarioReducer'; 

const initialState= {
  user: userReducer(),
  checklist: checklistReducer(),
  diario: diarioReducer(),


}


const MainReducer = (state, action) => ({
    user: userReducer(state.user, action),
    checklist: checklistReducer(state.checklist, action),
    diario: diarioReducer(state.diario, action),


});

export const StateContext = createContext();

export const StateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(MainReducer, initialState);
   


    return (
        <StateContext.Provider value={[state, dispatch]}>
            {children}
        </StateContext.Provider>
    );
};

export const useStateValue = () => useContext(StateContext);