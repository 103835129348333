import mock from '../mock'

const data =  
{
  id_reuniao: 6,
  id_reuniao_ref: "",
  reuniao: "RCOG VENDAS X LOGISTICA",
  descricao: "Reunião de alinhamento com a turma",
  quando: "31/07/2022 18:00",
  status: 0,
  perct_atividades: "100%",
  perct_assuntos: "90%",
  perct_participacao: "95",
  perct_pendente: "10",

  donos: [
    {
      id_dono: 2,
      dono: "João da Silva",
      avatar: ""
    },
    {
      id_dono: 1,
      dono: "João da Silva",
      avatar: ""
    }
  ],
  participantes: [
    {
      id_participante: 2,
      dono: "João da Silva",
      avatar: "",
      status: 0
    },
    {
      id_participante: 3,
      dono: "João da Silva",
      avatar: "",
      status: 0
    },
    {
      id_participante: 4,
      dono: "João da Silva",
      avatar: "",
      status: 0
    }
  ],
  atividades: [
    {
      id_atividade: 3,
      responsável: "João da Silva",
      avatar: "",
      assunto: "",
      prazo:"",
      status:""
      
    },
    {
      id_atividade: 2,
      responsável: "João da Silva",
      avatar: "",
      assunto: "",
      prazo:"",
      status:""
      
    },
    {
      id_atividade: 1,
      responsável: "João da Silva",
      avatar: "",
      assunto: "",
      prazo:"",
      status:""
      
    }
  ],
  assuntos: [
    {
      id_assunto: 18,
      assunto: "Férias dos colaboradores",
      status:"",
      atividades: [],
      comentarios: []
    },
    {
      id_assunto: 12,
      assunto: "Férias dos colaboradores",
      status:"",
      atividades: [],
      comentarios: []
    },
    {
      id_assunto: 11,
      assunto: "Férias dos colaboradores",
      status:"",
      atividades: [],
      comentarios: []
    }
  ]
  
}



mock.onGet('/meeting/list').reply(() => [200, data])

