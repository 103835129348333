import { Ability } from '@casl/ability'
import { initialAbility } from './initialAbility'
import { EncryptStorage } from 'encrypt-storage';

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this

const encryptStorage = new EncryptStorage('secret-key', {
    encAlgorithm: 'Rabbit',
  });

const userDataCrypt = encryptStorage.getItem('userDataCrypt');

const existingAbility = userDataCrypt ? userDataCrypt.ability : null

export default new Ability(existingAbility || initialAbility)
